import React, { Suspense, useEffect } from 'react';
import { BrowserRouter } from "react-router-dom";
import { ToastContainer } from 'react-toastify';

import { useDispatch, useSelector } from 'react-redux';
import { callCommonAction } from './redux/Common/CommonReducer';
import { GoogleOAuthProvider } from '@react-oauth/google';
import UserRoute from './routes/UserRoute';
import AdminRoute from './routes/AdminRoute';


function App() {
  const dispatch = useDispatch();

  const isAdminUrl = window.location.href.includes('admin');
  const { isLoggedIn, isAdmin, user } = useSelector((state) => state.common);


  useEffect(() => {
    // Only redirect if the user is logged out or has a status of 0 or 2
    if (user?.status === 2 || user?.status === 0) {
      localStorage.clear()
    }
  }, [user]); // Effect runs only when `user` changes
  


  /** Only Load Time Set State From LocalStorage */
  useEffect(() => {
    let raiseNotificationBell = localStorage.raiseNotificationBell != undefined ? localStorage.raiseNotificationBell == "true" ? true : false : false;
    if (localStorage.token !== undefined && localStorage.token !== null) {
      dispatch(callCommonAction({
        isLoggedIn: true,
        token: localStorage.token,
        user: JSON.parse(localStorage.user),
        subscription: localStorage.subscription === undefined ? null : JSON.parse(localStorage.subscription),
        isAdmin: localStorage.isAdmin === undefined ? false : localStorage.isAdmin === 'false' ? false : true,
        isSubscriptionCanceled: localStorage.isSubscriptionCanceled === undefined ? false : localStorage.isSubscriptionCanceled === false ? false : true
      }));
    }
    //without user logged in we also need raise Notification Bell
    dispatch(callCommonAction({ raiseNotificationBell: raiseNotificationBell }));
  }, [isLoggedIn, isAdmin]);

  return (
    <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
      <BrowserRouter>
        <ToastContainer autoClose={1500} />
        <Suspense>
          {isAdminUrl ? <AdminRoute /> : <UserRoute />}
        </Suspense>
      </BrowserRouter>
    </GoogleOAuthProvider>
  );
}

export default App;
