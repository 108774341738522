import React, { useEffect, useState } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { sendRequest } from '../../apis/APIs';
import { callCommonAction } from '../../redux/Common/CommonReducer';
import 'react-toastify/dist/ReactToastify.css'; // import first
import { toast } from 'react-toastify';
import { handleApiResponse } from '../../apis/apiUtils';
import { Helmet } from 'react-helmet';
import Helper from '../../apis/Helper';
import STRINGS from '../../common/strings/strings';
import { AppRoutes } from '../../configs';
import SetUpVideo from './SetUpVideo';
import CryptoJS from 'crypto-js';
import Col from 'react-bootstrap/Col';
import Nav from 'react-bootstrap/Nav';
import Row from 'react-bootstrap/Row';
import Tab from 'react-bootstrap/Tab';

const secretKey = process.env.REACT_APP_CRYPTO_SECRET_KEY; // Replace with a strong secret key

const ManageCrm = () => {
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    const { contentLoading, isAdmin, user, loading1 } = useSelector((state) => state.common);
    const dispatch = useDispatch();
    const [errorsInfo, setErrorsInfo] = useState({ api_key: '', location_owner_email: '' });
    const [userSetting, setUserSetting] = useState({});
    const [modalShow, setModalShow] = React.useState(false);


    useEffect(() => {
        var check_card = checkUserHasCard();
        if (check_card) {
            getUserSetting();
            if (searchParams.has('code')) {
                saveAuthorizationCode(searchParams.get("code"));
            }
        }
    }, []);

    const checkUserHasCard = () => {
        if (!isAdmin && user.is_card_enter !== 1) {
            navigate(AppRoutes.purchaseCreditRoute);
        }
        return true;
    };

    const saveAuthorizationCode = async (auth_code) => {
        try {
            const res = await sendRequest('/user/crm-add-code', 'POST', { authorization_code: auth_code });
            if (res.data.type === "success") {
                if (searchParams.has('code')) {
                    searchParams.delete('code');
                    setSearchParams(searchParams);
                }
                window.location.reload();
            }
        } catch (error) {
            toast.error('Something went wrong');
        }
    };

    /***** Get Api Key Data ***/
    const getUserSetting = async () => {
        try {
            dispatch(callCommonAction({ contentLoading: true, breadCrumb: ['Crm', 'Settings'] }));
            const res = await sendRequest('/user/crm-setting', 'GET');
            dispatch(callCommonAction({ contentLoading: false }));
            handleApiResponse(res);
            if (res.data.type === "success") {
                setUserSetting(res.data.data);
            }
        } catch (error) {
            toast.error('Something went wrong');
            dispatch(callCommonAction({ contentLoading: false }));
        }
    };

    /***** Update Api Key Data ***/
    const updateApiKeyData = async (e) => {
        e.preventDefault();
        if (checkFormIsValid(userSetting, "")) {
            try {
                dispatch(callCommonAction({ contentLoading: true }));
                const res = await sendRequest('/user/crm-setting', 'POST', userSetting);
                dispatch(callCommonAction({ contentLoading: false }));
                handleApiResponse(res);
                if (res.data.type === "success") {
                    setUserSetting(res.data.data);
                    localStorage.setItem('user', JSON.stringify(res.data.data));
                    dispatch(callCommonAction({ user: res.data.data }));
                }
            } catch (error) {
                toast.error('Something went wrong');
                dispatch(callCommonAction({ contentLoading: false }));
            }
        }
    };

    /** Save webhook url  **/
    const saveWebhookForm = async (e) => {
        e.preventDefault();
        if (checkWebhookUrlValid("")) {
            try {
                dispatch(callCommonAction({ loading1: true }));
                const res = await sendRequest(`/user/update-webhook-url`, 'POST', { webhook_url: userSetting.webhook_url });
                dispatch(callCommonAction({ loading1: false }));
                handleApiResponse(res);
                if (res.data.type === "success") {
                    //getUserSetting();
                }
            } catch (error) {
                dispatch(callCommonAction({ loading1: false }));
                toast.error('Something went wrong');
            }
        }
    };


    /** Handle Input Change Manage Heere */
    const handleChangeInput = (e) => {
        const { name, value } = e.target;

        if (name === 'location_owner_email') {
            setUserSetting((prev) => ({
                ...prev,
                [name]: value,
            }));
            checkFormIsValid({ ...userSetting, [name]: value }, name);
        } else {
            setUserSetting((prev) => ({
                ...prev,
                crm_settings: {
                    ...prev.crm_settings,
                    [name]: value
                },
            }));

            checkFormIsValid({ ...userSetting, crm_settings: { ...userSetting.crm_settings, [name]: value } }, name);
        }


    };


    const handleWebhookUrl = (event) => {
        const { name, value } = event.target;
        userSetting[name] = value;
        setUserSetting(userSetting);
        checkWebhookUrlValid(name);
    };

    const checkWebhookUrlValid = (fieldName) => {
        let errorMsg = ''; let errors = {}; let formVaildCheck = true;

        if ((fieldName == 'webhook_url' || fieldName == '') && (!/^(http|https):\/\/[^\s]+/.test(userSetting.webhook_url))) {
            errorMsg = userSetting.webhook_url == '' ? STRINGS.reqField : STRINGS.invalidWebhookUrl;
            formVaildCheck = false;
            errors.webhook_url = errorMsg;
        }

        setErrorsInfo(errors);
        return formVaildCheck;
    };





    const checkFormIsValid = (userSetting, fieldName) => {
        let errorMsg = ''; let errors = {}; let formVaildCheck = true;

        if ((fieldName == 'location_owner_email' || fieldName == '') && (!Helper.validateEmail(userSetting.location_owner_email))) {
            errorMsg = userSetting.location_owner_email == '' ? STRINGS.emailReq : STRINGS.invalidEmail;
            formVaildCheck = false;
            errors.location_owner_email = errorMsg;
        }

        setErrorsInfo(errors);
        return formVaildCheck;
    };

    const checkUserConfigrationID = () => {
        if (userSetting.configration_id !== undefined && userSetting.configration_id !== null) {
            return true;
        }
        return false;
    };

    const connectYourApp = () => {
        const options = {
            requestType: "code",
            redirectUri: `${process.env.REACT_APP_FRONTEND}/user/crm-settings`,
            clientId: process.env.REACT_APP_MARKET_PLACE_CLIENT_ID,
            scopes: [
                "contacts.write",
                "locations/tags.write",
                "oauth.write",
                "contacts.readonly",
                "locations/customFields.write",
                "locations/customFields.readonly",
                "contacts.notes.write", 
            ]
        };

        console.log(encodeURI(`${process.env.REACT_APP_MARKET_PLACE_BASE_URL}/oauth/chooselocation?response_type=${options.requestType}&redirect_uri=${options.redirectUri}&client_id=${options.clientId}&scope=${options.scopes.join(' ')}`));
       window.location = encodeURI(`${process.env.REACT_APP_MARKET_PLACE_BASE_URL}/oauth/chooselocation?response_type=${options.requestType}&redirect_uri=${options.redirectUri}&client_id=${options.clientId}&scope=${options.scopes.join(' ')}`);

    };



    const copyToClipboard = (inputValue) => {
        navigator.clipboard.writeText(inputValue)
            .then(() => {
                console.log('Text copied to clipboard');
            })
            .catch((err) => {
                console.error('Failed to copy text: ', err);
            });
    };

    const userEncrytedId = `${process.env.REACT_APP_ENDPOINT}/user/enrichment-request-non-agent-crm?feature=dnc&encryptedId=${encodeURIComponent(CryptoJS.AES.encrypt(String(user?._id), secretKey).toString())}`;

    return (
        <div className="content d-flex flex-column flex-column-fluid" id="kt_content">
            {/* {console.log(userSetting, 'userSetting')} */}
            <Helmet>
                <title>Leadboost | Crm Management</title>
                <meta name="description" content="Description goes here" />
                <meta name="keywords" content="Game, Entertainment, Movies" />
            </Helmet>
            {/**begin::Post**/}
            <div className="post d-flex flex-column-fluid" id="kt_post">
                {/**begin::Container**/}
                <div id="kt_content_container" className="container-xxl">
                    {/**begin::Card**/}
                    <div className="card">
                        {/**begin::Card header**/}
                        <div className="card-header align-content-center">
                            {/**begin::Card title**/}
                            <div className="card-title">
                                <h4>CRM Integration</h4>
                            </div>
                        </div>
                        {/**end::Card header**/}
                        {/**begin::Card body**/}
                        <div className="card-body">
                            <div className="row">
                                <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                                    <Row>
                                        <Col sm={2} className="border-bottom border-start border-top col-sm-2 p-0">
                                            <Nav variant="pills" className="flex-column">
                                                <Nav.Item className="m-0">
                                                    <Nav.Link eventKey="first" className="border-bottom nav-link rounded-0 tab py-4">
                                                        {checkUserConfigrationID() ?
                                                            <i className="fa-regular fa-circle-check fa-xl text-success align-baseline me-2"></i>
                                                            :
                                                            <i className="fa-regular fa-circle-xmark fa-xl text-danger align-baseline me-2"></i>
                                                        }
                                                        Connect App
                                                    </Nav.Link>
                                                </Nav.Item>
                                                <Nav.Item className="m-0">
                                                    <Nav.Link eventKey="second" className="border-bottom nav-link rounded-0 tab py-4">
                                                        {userSetting.location_owner_email !== null ?
                                                            <i className="fa-regular fa-circle-check fa-xl text-success align-baseline me-2"></i>
                                                            :
                                                            <i className="fa-regular fa-circle-xmark fa-xl text-danger align-baseline me-2"></i>
                                                        }
                                                        Business Email
                                                    </Nav.Link>
                                                </Nav.Item>
                                            </Nav>
                                        </Col>
                                        <Col sm={10} className="px-0">
                                            <Tab.Content style={{ backgroundColor: 'rgb(243 248 255)' }}>
                                                <Tab.Pane eventKey="first">
                                                    <div className='border'>
                                                        <div className='align-items-center d-flex h-150px justify-content-center'>
                                                            {checkUserConfigrationID() ?
                                                                <div className=''><span className='fs-2 text-success'>Your App Is Connected</span></div>
                                                                :
                                                                <div className=''><button type="button" className='btn btn-primary btn-sm' onClick={() => connectYourApp()}>Click To Connect Your App</button></div>
                                                            }
                                                        </div>
                                                    </div>
                                                </Tab.Pane>
                                                <Tab.Pane eventKey="second">
                                                    <form onSubmit={updateApiKeyData}>
                                                        <div className="">
                                                            <div className='border p-6'>
                                                                <div className='d-flex'>
                                                                    <div className="col-12 col-md-4">
                                                                        <div className="fv-row mb-7 fv-plugins-icon-container">
                                                                            <label className="fs-6 fw-semibold form-label mt-3">
                                                                                <h5 className="d-flex mt-2">Business Email</h5>
                                                                            </label>
                                                                            <div className="d-flex flex-column flex-md-row gap-3">
                                                                                <input type="email" onChange={handleChangeInput} value={userSetting?.location_owner_email} className={`form-control bg-white form-control-solid ${errorsInfo.location_owner_email ? 'is-invalid' : ''}`} name="location_owner_email" />
                                                                            </div>
                                                                            <div className="fv-plugins-message-container">
                                                                                <div className="fv-help-block">
                                                                                    <span role="alert">{errorsInfo.location_owner_email}</span>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className='col-12 mt-15 px-11'>
                                                                        <button type="submit" className='btn btn-primary btn-sm'>{
                                                                            contentLoading
                                                                                ?
                                                                                <span className="indicator-progress" style={{ display: 'block' }}>
                                                                                    Please wait...
                                                                                    <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                                                                </span>
                                                                                :
                                                                                <span className="indicator-label">Save</span>
                                                                        }</button>
                                                                    </div>
                                                                </div>
                                                                <div className="col-12">
                                                                    <div className="help-block">
                                                                        <p className='text-default'>Need help finding your Business Email ?  <span className='text-info' role="button" onClick={() => setModalShow(true)}>Click Here </span></p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </form>
                                                </Tab.Pane>
                                            </Tab.Content>
                                        </Col>
                                    </Row>
                                </Tab.Container>
                            </div>
                        </div>
                        {/**end::Card body**/}
                    </div>

                    {/**end::Card**/}
                </div>

            </div>
            {/**End::Post**/}




            {/**begin::Post**/}
            <div className="post d-flex flex-column-fluid" id="kt_post">
                {/**begin::Container**/}
                <div id="kt_content_container" className="container-xxl">
                    {/**begin::Card**/}
                    <div className="card">
                        {/**begin::Card header**/}
                        <div className="card-header align-content-center">
                            {/**begin::Card title**/}
                            <div className="card-title">
                                <h4>Setting up Webhooks for Data Transfer</h4>
                            </div>
                        </div>
                        {/**end::Card header**/}
                        {/**begin::Card body**/}

                        <div className='card-body connect-button-body'>
                            <div className='row'>
                                <form onSubmit={saveWebhookForm}>
                                    <div className="row">
                                        <div className='col-lg-12 mt-4'>
                                            <div className='d-flex'>
                                                <input
                                                    type="text"
                                                    name="webhook_url"
                                                    className={`form-control  me-2 ${errorsInfo.webhook_url ? 'is-invalid' : ''}`}
                                                    value={userSetting.webhook_url}
                                                    onChange={handleWebhookUrl}
                                                    aria-describedby="webhookUrlHelp"
                                                />
                                                <button type="submit" className='btn btn-primary btn-sm' style={{ width: 100 }} disabled={loading1 ? true : false}>{
                                                    loading1
                                                        ?
                                                        <span className="indicator-progress" style={{ display: 'block' }}>
                                                            Please wait...
                                                            <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                                        </span>
                                                        :
                                                        <span className="indicator-label">Save</span>
                                                }</button>


                                            </div>
                                            <div className="fv-plugins-message-container">
                                                <div className="fv-help-block">
                                                    <span role="alert">{errorsInfo.webhook_url}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                        {/**end::Card body**/}
                    </div>

                    {/**end::Card**/}
                </div>

            </div>
            {/**End::Post**/}

            {/**begin::Post**/}
            <div className="post d-flex flex-column-fluid" id="kt_post">
                <div id="kt_content_container" className="container-xxl">
                    {/**begin::Card**/}
                    <div className="card">
                        {/**begin::Card header**/}
                        <div className="card-header align-content-center">
                            {/**begin::Card title**/}
                            <div className="card-title">
                                <h4>Enrich endpoint</h4>
                            </div>
                        </div>
                        {/**end::Card header**/}

                        <div className='card-body connect-button-body'>
                            <div className="row">
                                <div className='col-lg-12 mt-4'>
                                    <div className='d-flex'>
                                        <input
                                            className={`form-control  me-2`}
                                            type="text"
                                            value={userEncrytedId}
                                            placeholder="Type something..."
                                            disabled={true}
                                        />
                                        <button className='btn btn-primary btn-sm' onClick={() => copyToClipboard(userEncrytedId)}>Copy</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <SetUpVideo show={modalShow} onHide={() => setModalShow(false)} fullscreen={false} />
        </div>
    );
};

export default ManageCrm;
